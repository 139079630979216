<template>
    <div v-if="loading" class="loading-spinner">
        <DsSpinner />
    </div>

    <DsInlineAlert
        v-else-if="error"
        leading-icon
        class="error-alert"
        type="critical"
    >
        <i18n-t
            v-if="isInternalPayment"
            tag="span"
            keypath="internalLoadError"
        >
            <template #knownIssues>
                <a
                    href="https://knownissues.keap.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {{ $t('knownIssues') }}
                </a>
            </template>

            <template #support>
                <a
                    href="https://keap.com/contact"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {{ $t('support') }}
                </a>
            </template>
        </i18n-t>

        <span v-else>
            {{ errorText }}
        </span>
    </DsInlineAlert>

    <img
        v-else-if="preview"
        class="preview"
        src="../keap-pay-preview.png"
    />

    <div v-else class="offset-container" :class="[{ preview: isReadonly }]">
        <!-- eslint-disable vue/component-name-in-template-casing -->
        <rainforest-payment
            v-if="merchantDataLoaded"
            ref="rainforestPaymentComponent"
            v-bind="$options.RAINFOREST_PAYMENT_COMPONENT_SETTINGS"
            :session-key="rainforestSessionKey"
            :payment-method-config-id="rainforestPaymentMethodConfigId"
            data-qa="rainforestPaymentComponent"
            @error="handleLoadingError"
            @approved="handleApprovedPayment"
            @invalid="handleInvalid"
            @valid="handleValid"
        />
    </div>
</template>

<script>
import { RAINFOREST_PAYMENT_COMPONENT_SETTINGS } from '../rainforest.constants.js';
import { useRainforestPaymentScript } from '../useRainforest';
import { mapState, mapGetters } from 'vuex';
import { getContactDisplayName } from '@keap-web/shared-ui';

export default {
    RAINFOREST_PAYMENT_COMPONENT_SETTINGS,

    props: {
        paymentSource: {
            type: String,
            required: true,
        },
        manualPayment: {
            type: [Object, Number],
            default: null,
        },
        publicSession: {
            type: Object,
            default: null,
        },
    },

    setup() {
        useRainforestPaymentScript();
    },

    data() {
        return {
            authErrorMsg: '',
            loading: true,
            error: false,
            sessionKey: null,
            preview: false,
            paymentMethodConfigId: null,
            consent: 'NO_CONSENT',
            customPayAmount: null,
        };
    },

    computed: {
        ...mapState({
            invoice: ({ sales }) => sales.invoice,
            contactInfoId: ({ contacts }) => contacts.contact.id,
            contact: ({ contacts }) => contacts.contact,
            userId: ({ auth }) => auth.user.id,
            publicInvoice: ({ sales }) => sales.publicInvoice,
            checkoutFormData: ({ sales }) => sales.checkoutFormData,
            paymentAccounts: ({ billing }) => billing.paymentAccounts,
        }),

        ...mapGetters({
            getDefaultProcessorSubtype: 'sales/getDefaultProcessorSubtype',
            getDefaultProcessorId: 'sales/getDefaultProcessorId',
        }),

        rainforestSessionKey() {
            if (this.paymentSource === 'public-save-card') return this.publicSession?.sessionKey;

            return this.sessionKey;
        },

        rainforestPaymentMethodConfigId() {
            if (this.paymentSource === 'public-save-card') return this.publicSession?.paymentMethodConfigId;

            return this.paymentMethodConfigId;
        },

        merchantDataLoaded() {
            return Boolean(this.rainforestSessionKey && this.rainforestPaymentMethodConfigId);
        },

        isReadonly() {
            return this.paymentSource === 'readonly';
        },

        errorText() {
            if (this.paymentSource === 'public-invoice') return this.$t('public.invoice.rainforestError');
            if (this.paymentSource === 'checkout-form') return this.$t('public.checkout.rainforestError');

            return this.$t('publicLoadError');
        },

        isInternalPayment() {
            return this.paymentSource === 'manual-product' || this.paymentSource === 'manual-invoice';
        },

        hasDeposit() {
            const amountDue = this.publicInvoice?.payPlan?.amountDue;

            return Boolean(amountDue && this.invoice?.totalPaid < amountDue);
        },

        payAmount() {
            if (this.hasDeposit && this.publicInvoice?.paymentsMade) {
                return Number(this.publicInvoice.payPlan.amountDue);
            }

            return Number(this.customPayAmount || this.publicInvoice.totalRemainingBalance || this.publicInvoice.totalDueNow);
        },
    },

    mounted() {
        this.$bus?.$on('SUBMIT_RAINFOREST_PAYMENT', this.submitRainforestPayment);
        this.$bus?.$on('UPDATE_PUBLIC_INVOICE_PAYMENT', this.updatePayAmount);
        this.$bus?.$on('UPDATE_MANUAL_PAYMENT', this.updatePayAmount);
        this.$bus?.$on('UPDATE_INVOICE_PAYMENT', this.updatePayAmount);
        this.load();
    },

    beforeUnmount() {
        this.$bus.$off('SUBMIT_RAINFOREST_PAYMENT');
        this.$bus.$off('UPDATE_PUBLIC_INVOICE_PAYMENT');
        this.$bus.$off('UPDATE_MANUAL_PAYMENT');
        this.$bus.$off('UPDATE_INVOICE_PAYMENT');
    },

    methods: {
        updatePayAmount({ amount }) {
            this.customPayAmount = amount;
        },

        load() {
            if (this.paymentSource === 'manual-invoice') this.createRainforestManualInvoicePaymentIntent();
            if (this.paymentSource === 'manual-product') this.createRainforestManualProductPaymentIntent();
            if (this.paymentSource === 'save-card') this.createRainforestSaveCardIntent();
            if (this.paymentSource === 'public-save-card') this.loading = false;
            if (this.paymentSource === 'checkout-form') this.createRainforestCheckoutFormPaymentIntent();
            if (this.paymentSource === 'public-invoice') this.createRainforestPublicInvoicePaymentIntent();
            if (this.paymentSource === 'readonly') this.showPreview();
        },

        showPreview() {
            this.preview = true;
            this.loading = false;
        },

        async createRainforestManualInvoicePaymentIntent() {
            try {
                const payload = {
                    invoiceId: `${this.invoice.id}`,
                    invoiceNumber: this.invoice.invoiceNumber,
                    contactId: parseInt(this.contactInfoId, 10),
                    createdBy: this.userId,
                    paymentMethodBillingContactDTO: {
                        billingName: getContactDisplayName(this.invoice.contact),
                        billingAddrLine1: this.invoice.shippingInformation.street1,
                        billingCity: this.invoice.shippingInformation.city,
                        billingEmail: this.invoice.contact.email,
                    },
                };

                const data = await this.$store.dispatch('sales/CREATE_RAINFOREST_MANUAL_INVOICE_PAYMENT_INTENT', payload);

                this.sessionKey = data?.sessionKey;
                this.paymentMethodConfigId = data?.paymentMethodConfigId;

                if (!this.merchantDataLoaded) throw data;
            } catch (error) {
                this.handleLoadingError();
            }

            this.loading = false;
        },

        async createRainforestSaveCardIntent() {
            try {
                const payload = {
                    contactId: parseInt(this.contactInfoId, 10),
                    createdBy: this.userId,
                    source: 'Card on file - Save card',
                    paymentMethodBillingContactDTO: {
                        billingName: getContactDisplayName(this.contact),
                        billingEmail: this.contact.email,
                    },
                };

                const data = await this.$store.dispatch('sales/CREATE_RAINFOREST_PAYMENT_INTENT', payload);

                this.sessionKey = data.sessionKey;
                this.paymentMethodConfigId = data.paymentMethodConfigId;

                if (!this.merchantDataLoaded) throw data;
            } catch (error) {
                this.handleLoadingError();
            }

            this.loading = false;
        },

        async createRainforestManualProductPaymentIntent() {
            try {
                const payload = {
                    contactId: parseInt(this.contactInfoId, 10),
                    createdBy: this.userId,
                    source: 'Manual product payment',
                    paymentMethodBillingContactDTO: {
                        billingName: getContactDisplayName(this.contact),
                        billingEmail: this.contact.email,
                    },
                };

                const data = await this.$store.dispatch('sales/CREATE_RAINFOREST_PAYMENT_INTENT', payload);

                this.sessionKey = data?.sessionKey;
                this.paymentMethodConfigId = data?.paymentMethodConfigId;

                if (!this.merchantDataLoaded) throw data;
            } catch (error) {
                this.handleLoadingError();
            }

            this.loading = false;
        },

        async createRainforestCheckoutFormPaymentIntent() {
            try {
                const payload = { paymentMethodBillingContactDTO: {} };

                const data = await this.$store.dispatch('sales/CREATE_RAINFOREST_CHECKOUT_FORM_PAYMENT_INTENT', payload);

                this.sessionKey = data?.sessionKey;
                this.paymentMethodConfigId = data?.paymentMethodConfigId;

                if (!this.merchantDataLoaded) throw data;
            } catch (error) {
                this.handleLoadingError();
            }

            this.loading = false;
        },

        async createRainforestPublicInvoicePaymentIntent() {
            try {
                const payload = {
                    invoiceId: this.publicInvoice?.xid,
                    contactId: this.publicInvoice?.contact?.contactId,
                    source: 'Public invoice payment',
                    paymentMethodBillingContactDTO: {
                        billingName: getContactDisplayName(this.publicInvoice?.contact),
                        billingAddrLine1: this.publicInvoice?.contact?.address?.street1,
                        billingCity: this.publicInvoice?.contact?.address?.locality,
                        billingEmail: this.publicInvoice?.company?.email,
                    },
                };

                const data = await this.$store.dispatch('sales/CREATE_RAINFOREST_PUBLIC_INVOICE_PAYMENT_INTENT', payload);

                this.sessionKey = data?.sessionKey;
                this.paymentMethodConfigId = data?.paymentMethodConfigId;

                if (!this.merchantDataLoaded) throw data;
            } catch (error) {
                this.handleLoadingError();
            }

            this.loading = false;
        },

        submitRainforestPayment(consent = 'NO_CONSENT') {
            this.consent = consent;
            this.$store.commit('sales/SET_PROCESSING', true);
            this.$refs?.rainforestPaymentComponent?.submit();
        },

        async saveCard(data) {
            this.$bus.$emit('SAVE_RAINFOREST_CARD', data);
        },

        async processManualInvoicePayment(data) {
            const payload = {
                contactId: Number(data.metadata.contact_id),
                amount: this.customPayAmount || this.invoice.total,
                orderId: data.metadata.invoice_id,
                cardNumber: data.card.last_4,
                nameOnCard: data.billing_contact.name,
                expirationMonth: data.card.exp_month,
                expirationYear: data.card.exp_year,
                country: data.billing_contact.country,
                region: data.billing_contact.state,
                postalCode: data.billing_contact.postal_code,
                paymentToken: data.payment_method_id,
                tokenProcessor: 'Rainforest',
                paymentMethodCardBrand: data.card.brand,
                cardType: data.card.type,
                paymentMethodType: 'token',
                consent: this.consent,
                paymentGatewayId: this.getDefaultProcessorId,
                paymentSubType: this.getDefaultProcessorSubtype,
                fingerprint: data.fingerprint,
                ...this.manualPayment,
            };

            const response = await this.$store.dispatch('sales/PROCESS_RAINFOREST_MANUAL_INVOICE_PAYMENT', payload);

            if (response?.successful) {
                this.$bus.$emit('RAINFOREST_SUCCESSFUL_PAYMENT');
                this.$bus.$emit('REFRESH_INVOICES');
                this.$bus.$emit('INVOICE_PAYMENT_SUCCESSFUL');

                if (this.$route === 'contact.record') this.$store.dispatch('contacts/LOAD_CONTACT_RECORD', this.contact.id);
            } else {
                this.handleProcessingError();
            }
        },

        async processManualProductPayment(data) {
            const payload = {
                cardNumber: data.card.last_4,
                nameOnCard: data.billing_contact.name,
                expirationMonth: data.card.exp_month,
                expirationYear: data.card.exp_year,
                country: data.billing_contact.country,
                region: data.billing_contact.state,
                postalCode: data.billing_contact.postal_code,
                paymentToken: data.payment_method_id,
                tokenProcessor: 'Rainforest',
                paymentMethodCardBrand: data.card.brand,
                cardType: data.card.type,
                paymentMethodType: 'token',
                consent: this.consent,
                paymentGatewayId: this.getDefaultProcessorId,
                paymentSubType: this.getDefaultProcessorSubtype,
                fingerprint: data.fingerprint,
                ...this.manualPayment,
            };

            const response = await this.$store.dispatch('sales/PROCESS_RAINFOREST_MANUAL_PRODUCT_PAYMENT', payload);

            if (response.successful) {
                this.$bus.$emit('RAINFOREST_SUCCESSFUL_PAYMENT');
                this.$bus.$emit('REFRESH_INVOICES');
            } else {
                this.handleProcessingError();
            }
        },

        async processCheckoutFormPayment(data) {
            const payload = {
                email: this.checkoutFormData.email,
                nameOnCard: this.checkoutFormData.nameOnCard,
                paymentType: 'token',
                postalCode: data.billing_contact.postal_code,
                country: data.billing_contact.country,
                region: data.billing_contact.state,
                paymentToken: data.payment_method_id,
                tokenProcessor: 'rainforest',
                checkoutFormIdentifier: this.$route.params?.id?.toLowerCase()?.trim(),
                upsells: this.checkoutFormData.upsells,
                promoCodes: this.checkoutFormData.promoCodes,
                payAmount: this.checkoutFormData.payAmount,
                cardType: data.card.type,
                cardNumber: data.card.last_4,
                expirationMonth: data.card.exp_month,
                expirationYear: data.card.exp_year,
                paymentMethodCardBrand: data.card.brand,
                fingerprint: data.fingerprint,
                consent: this.checkoutFormData.consent,
            };

            const response = await this.$store.dispatch('sales/PROCESS_CHECKOUT_PAYMENT', payload);

            if (response.successful) {
                this.$bus.$emit('CHECKOUT_FORM_PAYMENT_MADE');
            } else {
                this.handleProcessingError();
            }
        },

        async processPublicInvoicePayment(data) {
            const payload = {
                nameOnCard: data.billing_contact.name,
                cardNumber: data.card.last_4,
                cardType: data.card.type,
                cardBrand: data.card.brand,
                expirationMonth: data.card.exp_month,
                expirationYear: data.card.exp_year,
                postalCode: data.billing_contact.postal_code,
                country: data.billing_contact.country,
                invoiceXID: this.publicInvoice?.xid,
                region: data.billing_contact.state,
                paymentType: 'token',
                paymentToken: data.payment_method_id,
                tokenProcessor: 'rainforest',
                payAmount: this.payAmount,
                fingerprint: data.fingerprint,
            };

            const response = await this.$store.dispatch('sales/PROCESS_PUBLIC_PAYMENT', payload);

            if (response.successful) {
                this.$bus.$emit('RAINFOREST_SUCCESSFUL_PAYMENT');
            } else {
                this.handleProcessingError();
            }
        },

        handleInvalid() {
            this.$bus.$emit('UPDATE_RAINFOREST_PAYMENT_STATUS', false);
        },

        handleValid() {
            this.$bus.$emit('UPDATE_RAINFOREST_PAYMENT_STATUS', true);
        },

        handleApprovedPayment(payment) {
            const [paymentDetail] = payment?.detail || [];

            if (!paymentDetail?.data || !paymentDetail?.status || paymentDetail?.errors || paymentDetail?.status !== 'SUCCESS') {
                this.handleProcessingError();

                return;
            }

            if (this.paymentSource === 'save-card') this.saveCard(paymentDetail.data);
            if (this.paymentSource === 'public-save-card') this.savePublicCard(paymentDetail.data);
            if (this.paymentSource === 'manual-invoice') this.processManualInvoicePayment(paymentDetail.data);
            if (this.paymentSource === 'manual-product') this.processManualProductPayment(paymentDetail.data);
            if (this.paymentSource === 'checkout-form') this.processCheckoutFormPayment(paymentDetail.data);
            if (this.paymentSource === 'public-invoice') this.processPublicInvoicePayment(paymentDetail.data);
        },

        async savePublicCard(data) {
            const payload = {
                billingAddress1: data.billing_contact.address_line_1,
                billingAddress2: data.billing_contact.address_line_2,
                billingCity: data.billing_contact.city,
                billingCountry: data.billing_contact.country,
                billingEmail: data.billing_contact.email,
                billingPhone: data.billing_contact.phone,
                billingPostalCode: data.billing_contact.postal_code,
                billingRegion: data.billing_contact.state,
                cardNumber: data.card.last_4,
                consentType: 'EXPLICIT_CONSENT',
                expirationMonth: `${data.card.exp_month}`,
                expirationYear: `${data.card.exp_year}`,
                fingerprint: data.fingerprint,
                lastFour: data.card.last_4,
                nameOnCard: data.billing_contact.name,
                paymentMethodCardBrand: data.card.brand,
                paymentMethodId: data.payment_method_id,
                paymentMethodType: 'CREDIT',
            };

            this.$bus.$emit('RAINFOREST_SUCCESSFUL_PAYMENT', payload);
        },

        handleLoadingError() {
            this.error = true;
        },

        handleProcessingError() {
            this.load();
            this.$store.commit('sales/SET_PROCESSING', false);
            this.$bus.$emit('RAINFOREST_FAILED_PAYMENT');
            this.$error({ message: this.$t('billing.paymentError.default'), bottom: true });
        },
    },
};
</script>

<style lang="scss" scoped>
// TODO: remove css hack once padding prop is provided
.offset-container {
    margin: 0 -1rem 0 -1rem;

    &.preview {
        pointer-events: none;
    }
}

.loading-spinner {
    margin-bottom: $spacing-200;
    padding-bottom: $spacing-200;
    display: flex;
    justify-content: center;
}

.error-alert {
    margin-bottom: $spacing-200;
}

.preview {
    margin-bottom: $spacing-300;
}
</style>

<i18n>
{
    "en-us": {
        "knownIssues": "Known Issues",
        "support": "Customer Support",
        "internalLoadError": "There was a problem loading your Keap Pay account. Please check Keap's {knownIssues} page for updates, or contact {support}.",
        "publicLoadError": "There was a problem loading the payment form. Please try again or contact the issuer."
    }
}
</i18n>
